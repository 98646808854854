import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import AnchorLink from '../components/AnchorLink';
import Bio from '../components/Bio'
import PostExcerpt from '../components/PostExcerpt';
import Tag from '../components/Tag';


export default class TagPage extends React.Component {
    render() {
        const siteMetadata = this.props.data.site.siteMetadata;
        const { tag, posts, tags } = this.props.data;
        const fullUrl = (siteMetadata.siteUrl + tag.slug).replace(/([^:])\/\//g, '$1/');

        return (
            <>
                <Helmet title={`Tag “${tag.name}” | ${siteMetadata.title}`}>
                    <html lang="en"/>
                    <link rel="canonical" href={fullUrl} />
                    <meta name="author" value={siteMetadata.author.fullName} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`Tag “${tag.name}”`} />
                    <meta property="og:site_name" content={siteMetadata.title} />
                    <meta property="twitter:site:id" content={siteMetadata.twitterId} />
                    <link rel="alternate" type="application/json+oembed" href="/oembed.json" />
                </Helmet>
                <main className="tag-page post-list">
                    <h1>Posts tagged with “{tag.name}”</h1>
                    {posts.edges.map(({ post }) => (<PostExcerpt key={post.fields.slug} post={post} />))}
                    <AnchorLink className="back-to-top" href="#">back to top</AnchorLink>
                </main>
                <aside>
                    <Bio person={siteMetadata.author} />
                    <div className="sticky">
                        <section className="tag-list">
                            <h1>All tags</h1>
                            <ul>
                                {tags.edges.map(({ tag }) => (
                                    <li key={tag.slug} className="tag-item"><Tag value={tag}/></li>
                                ))}
                            </ul>
                        </section>
                    </div>
                </aside>
            </>
        )
    }
}

export const pageQuery = graphql`
  query TagBySlug($slug: String!) {
    site {
        siteMetadata {
            title
            siteUrl
            twitterId
            author { ...BioPerson }
        }
    }
    tag: tagField(slug: { eq: $slug }) {
        name
        slug
    }
    posts: allMarkdownRemark(filter: { fields: { tagSlugs: { eq: $slug } } },
                             sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
            post: node { ...PostExcerpt }
        }
    }
    tags: allTagField(sort: { fields: [name] }) {
        edges {
            tag: node { ...Tag }
        }
    }
  }
`
